<template>
    <div class="d-flex flex-column justify-content-center align-items-center" style="height: 82vh !important">
        <img :src="loaderLogo" style="max-width: 15%; height: auto" alt="Academy Si2p Logo" />
        <div class="spinner-primary spinner-border" style="width: 3rem; height: 3rem;" />
    </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
    computed: {
        ...mapGetters(['layoutConfig']),

        loaderLogo() {
            return process.env.BASE_URL + this.layoutConfig('loader.logo');
        }
    }
};
</script>